import importRejectionHandler from '../utils/importRejectionHandler';

const getComponent = (controllerName, ...args) => {
  /*
    Adding this because template strings cause the linter to explode and stop linting
    string concatenation actually works in this case.
  */
  // eslint-disable-next-line prefer-template
  import(/* webpackChunkName: "pageChunk" */ '../../chunks/' + controllerName)
    .then(({ default: pageChunk }) => {
      pageChunk(...args);
    }).catch((error) => {
      if (error.name === 'ChunkLoadError') {
        importRejectionHandler(error);
      }
      // eslint-disable-next-line no-console
      console.log(error.message);
    });
};

export default getComponent;
