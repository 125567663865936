const trackpointScriptId = 'trackpoint-script';

const loadTrackpoint = () => {
  if (document.getElementById(trackpointScriptId) || window.trackPoint) return;

  const trackpointScript = document.createElement('script');
  trackpointScript.src = 'https://d2ywvfgjza5nzm.cloudfront.net/trackpoint.js';
  trackpointScript.type = 'text/javascript';
  trackpointScript.id = trackpointScriptId;

  trackpointScript.addEventListener('load', () => {
    if (window.trackpointQueue === undefined) {
      return;
    }

    window.trackpointQueue.forEach((event) => {
      window.trackPoint.trigger(event);
    });
  });

  document.head.appendChild(trackpointScript);
};

export default () => { loadTrackpoint(); };
