import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import bootstrapper from '../bootstrapper';

export const setSentryContext = (moduleName, extraContext = {}) => {
  if (!bootstrapper.sentryDsn) {
    return;
  }

  if (bootstrapper.userUuid) {
    Sentry.setUser({ id: bootstrapper.userUuid });
  }

  Sentry.setTag('logger', 'JavaScript');

  if (moduleName) {
    Sentry.setTag('module', moduleName);
  }

  Sentry.setContext('data', {
    amplitudeEnabled: bootstrapper.amplitudeEnabled,
    facebookPixelEnabled: bootstrapper.facebookPixelEnabled,
    activeExperiments: bootstrapper.activeExperiments
      ? JSON.stringify(bootstrapper.activeExperiments, null, 2) : undefined,
    locale: bootstrapper.locale,
    host: bootstrapper.host,
    query: window.location.search,
    ...extraContext,
  });
};

const experimentsList = () => (
  bootstrapper.activeExperiments
    && bootstrapper.activeExperiments.active_experiment
    && bootstrapper.activeExperiments.active_experiment.length !== 0
    ? bootstrapper.activeExperiments.active_experiment.join(', ') : undefined
);

export default (moduleName, extraContext = {}, initOptions = {}) => {
  if (bootstrapper.sentryDsn) {
    Sentry.init({
      dsn: bootstrapper.sentryDsn,
      ignoreErrors: ['Non-Error promise rejection captured'],
      integrations: [new BrowserTracing()],
      tracesSampler: () => {
        const trackableModuleNames = ['Landing Page Template', 'Landing'];
        return trackableModuleNames.includes(moduleName) ? 0.25 : 0;
      },
      initialScope: {
        tags: {
          Domain: bootstrapper.host,
          Experiments: experimentsList(),
        },
      },
      ...initOptions,
    });
    setSentryContext(moduleName, extraContext);

    window.Sentry = Sentry;
  }
};
