import analytics from '../analytics';
import bootstrapper from '../bootstrapper';

export default (caller = analytics) => {
  if (bootstrapper.identifyEvent) {
    const { userId, properties, options } = bootstrapper.identifyEvent;
    caller.identify(userId, properties, options);
  }

  if (bootstrapper.trackingEvents) {
    bootstrapper.trackingEvents.forEach((trackingEvent) => {
      const { event, properties, options } = trackingEvent;

      caller.track(event, properties, options);
    });
  }

  if (bootstrapper.resetEvent) {
    caller.reset();
  }
};
