export default (event) => {
  const { target } = event;
  const applicationDeadlineElement = target.parentElement
    .querySelector('.js-application-deadline');
  const applicationDeadlineTextElement = target.parentElement
    .querySelector('.js-application-deadline-text');
  const selectedOption = target.options[target.selectedIndex];
  const applicationDeadlineText = selectedOption.dataset.applicationText;
  const applicationDeadlineDate = selectedOption.dataset.applicationDeadline;

  if (applicationDeadlineDate) {
    applicationDeadlineTextElement.innerHTML = applicationDeadlineText;
    applicationDeadlineElement.innerHTML = applicationDeadlineDate;
  }
};
