import ElementNotFoundError from '../errors/ElementNotFoundError';
import { getSelectedRadioValue } from '../utils/form';

const constants = {};
const state = {};

const formGroup = (attributeName) => {
  const className = [constants.modelName, attributeName].join('_');
  const element = constants.formElement.querySelector(`.${className}`);
  if (!element) { throw new ElementNotFoundError('Enrollment Form: Form group not found'); }

  return element;
};

const selectTag = (attributeName) => {
  const element = formGroup(attributeName).querySelector('select');
  if (!element) { throw new ElementNotFoundError('Enrollment Form: Select tag not found'); }

  return element;
};

const selectTagHandler = (event, attributeName) => {
  const { value } = event.target;
  const inputData = constants.otherInputs[attributeName];
  const stateData = state.otherInputs[attributeName];

  if (inputData.value === value) {
    stateData.hidden = false;
    formGroup(inputData.otherAttributeName).classList.remove('hidden');
  } else if (!stateData.hidden) {
    formGroup(inputData.otherAttributeName).classList.add('hidden');
  }
};

const setOtherOptionHandler = (attributeName) => {
  selectTag(attributeName)
    .addEventListener('change', event => selectTagHandler(event, attributeName));
};

const initializeNationalityToggle = () => {
  const radios = document.querySelectorAll(constants.nationalityRadiosSelector);

  if (radios.length) {
    const nationality = constants.formElement.querySelector(
      constants.nationalitySelector,
    );
    let radioValue = getSelectedRadioValue(radios);

    radios.forEach(radio => radio.addEventListener('change', () => {
      radioValue = getSelectedRadioValue(radios);

      if (radioValue === 'true') {
        nationality.disabled = 'disabled';
        nationality.value = '';
        nationality.classList.remove('filled');
      } else {
        nationality.disabled = '';
      }
    }));

    if (radioValue === 'true') {
      nationality.disabled = 'disabled';
    } else {
      nationality.disabled = '';
      nationality.classList.add('filled');
    }
  }
};

export default () => {
  constants.formElement = document.querySelector('#salesforce_enrollment_form');
  constants.modelName = 'salesforce_enrollment';
  constants.otherInputs = {
    your_industry: {
      otherAttributeName: 'your_industry_other',
      value: 'Others',
    },
    your_function: {
      otherAttributeName: 'your_function_other',
      value: 'Others',
    },
  };

  constants.nationalityRadiosSelector = 'input[type=radio][name="salesforce_enrollment[nationality_same_as_residence]"]';
  constants.nationalitySelector = '.form-nationality';

  state.otherInputs = {
    your_industry: {
      hidden: true,
    },
    your_function: {
      hidden: true,
    },
  };

  if (!constants.formElement) {
    return false;
  }

  initializeNationalityToggle();

  Object.entries(constants.otherInputs).forEach(
    ([attributeName]) => {
      setOtherOptionHandler(attributeName);
      selectTag(attributeName).dispatchEvent(new CustomEvent('change'));
    },
  );

  return true;
};
