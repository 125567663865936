export const getCookie = (cookieName) => {
  const cookies = document.cookie;

  const startsAt = cookies.indexOf(cookieName);

  if (startsAt >= 0) {
    return cookies.substring(startsAt).split(';')[0].split('=')[1];
  }
  return '';
};

export default getCookie;
