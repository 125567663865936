import * as Sentry from '@sentry/browser';
import {
  ebanxCheckoutEnabled, creditCardSubmitDisabled,
  paymentFormPreventSubmitOnEnterKey, enableCheckoutSubmitButton,
  displayFlashErrorMessage, removeFlashErrorMessage,
} from './checkoutHelpers';
import { clientSideFailedPaymentPayload, createFailedPayment } from './createFailedPayment';
import bootstrapper from '../bootstrapper';
import i18n from '../i18n.js.erb';

const ebanxPaymentDataHandler = (stringifiedPaymentData) => {
  // Insert the token ID into the form so it gets submitted to the server
  const paymentForm = document.getElementById('payment-form');
  const hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'ebanxParams');
  hiddenInput.setAttribute('value', stringifiedPaymentData);
  paymentForm.appendChild(hiddenInput);
  // Submit the form
  paymentForm.submit();
};

const showEbanxForeignCardModal = () => {
  const foreignCardModal = document.getElementById('ebanx-foreign-card-modal');
  foreignCardModal.classList.remove('modal--close');
  foreignCardModal.classList.add('modal--open');
};

const countryOfResidenceCodeLowerCase = () => {
  const paymentForm = document.getElementById('payment-form');
  const { countryOfResidenceCode } = paymentForm.dataset;
  if (countryOfResidenceCode) {
    return countryOfResidenceCode.toLowerCase();
  }
  return '';
};

const errorLogData = (stringifiedPaymentData) => {
  const data = bootstrapper.ebanxUserDataToLog || {};
  const { customerName, customerEmail } = bootstrapper.ebanxUserDataToPrefill || {};
  return {
    customer_email: customerEmail,
    customer_name: customerName,
    country: data.country,
    country_code: countryOfResidenceCodeLowerCase(),
    program_sfid: data.program_sfid,
    batch_sfid: data.batch_sfid,
    payment_data: stringifiedPaymentData,
  };
};

const errorClassifier = (error) => {
  let errorCode = '';
  let userErrorMessage = '';
  let recordErrorMessage = '';
  let createPaymentRecord = true;

  if (!error.message) {
    return {
      errorCode, userErrorMessage, recordErrorMessage, createPaymentRecord,
    };
  }

  recordErrorMessage = error.message;

  const paymentForm = document.getElementById('payment-form');
  const { lang } = document.documentElement;
  const errorMessages = i18n[lang].ebanx.errors;

  // Handle issues without error code
  if (error.message === 'Validation error for payment type "undefined"') {
    userErrorMessage = errorMessages.choose_payment_option;
    return {
      errorCode, userErrorMessage, recordErrorMessage, createPaymentRecord,
    };
  }
  if (error.message.includes('Validation error for ')) {
    // other errors like 'billing address data', 'personal info data' handled by DropIn itself
    createPaymentRecord = false;
    return {
      errorCode, userErrorMessage, recordErrorMessage, createPaymentRecord,
    };
  }

  // To check if the error has a specific error code
  const errRegex = /BP-\D{1,3}-\d{1,3}/;
  const errorRegexMatch = error.message.match(errRegex);
  errorCode = errorRegexMatch ? errorRegexMatch[0] : '';

  switch (errorCode) {
    case 'BP-DR-95': // Parameter card.card_name must be valid
    case 'BP-DR-36': // Payment type is not active
    case 'BP-DR-61': // It was not possible to create the token for this credit card
    case 'BP-DR-77': // Country is not enabled
    case 'BP-DR-78': // Country not enabled for merchant
      userErrorMessage = errorMessages.processing_error;
      break;
    case 'BP-DR-55': // Parameter card.card_cvv is invalid
      userErrorMessage = errorMessages.incorrect_cvc;
      break;
    case 'BP-DR-83': // Foreign credit card cannot be processed
      if (paymentForm.classList.contains('js-first-payment')) {
        showEbanxForeignCardModal();
        createPaymentRecord = false;
      } else {
        userErrorMessage = errorMessages.foreign_card;
      }
      break;
    case 'BP-DR-35': // Invalid payment_type_code: XXX
    case 'BP-DR-75': // Card number is invalid
    case 'BP-DR-101': // Card is not eligible for ecommerce
      userErrorMessage = errorMessages.card_declined;
      break;
    case 'BP-DR-48': // Field card is required for this payment type
    case 'BP-DR-49': // Field card.card_number is required
    case 'BP-DR-56': // Field card.card_due_date is required
    case 'BP-DR-67': // Field card.card_due_date is invalid
      createPaymentRecord = false; // handled by Dropin messages
      break;
    default:
      userErrorMessage = errorMessages.processing_error;
      break;
  }

  return {
    errorCode, userErrorMessage, recordErrorMessage, createPaymentRecord,
  };
};

const createClientSideFailedPayment = async (error, event) => {
  // transactionUUID First part for Enrollment.
  // transactionUUID Second part for Payment Installment.
  const transactionUUID = document.getElementById('shop_payment_installment_transaction_uuid') || document.getElementById('transaction_uuid');
  const body = clientSideFailedPaymentPayload('ebanx', error, transactionUUID.value);
  let response;

  await createFailedPayment(body).then(res => res.json())
    .then((res) => {
      response = res;
      transactionUUID.value = res.transaction_uuid;
      enableCheckoutSubmitButton(event);
    });

  return response;
};

const mockedResponseBody = () => {
  const paymentForm = document.getElementById('payment-form');
  const { ebanxMockResult } = paymentForm.dataset;
  // Replace for testing a specific response like:
  // const ebanxMockResult = '{"status":"ERROR","status_code":"BP-DR-83","status_message":"blah"}';
  return ebanxMockResult;
};

const nonProductionEnvironment = () => {
  const mockedEnvs = ['test', 'review_app', 'development'];
  return mockedEnvs.find(v => bootstrapper.appEnv === v) !== undefined;
};

const expectedFormatForDropinResponse = stringifiedPaymentData => stringifiedPaymentData.includes('card')
    && stringifiedPaymentData.includes('token')
    && !stringifiedPaymentData.includes('BP-');

const paymentSubmitErrorHandler = async (error, event) => {
  const errorHash = errorClassifier(error);
  if (errorHash.createPaymentRecord) {
    displayFlashErrorMessage(errorHash.userErrorMessage);
    const errorObject = {
      status_code: errorHash.errorCode,
      status_message: errorHash.recordErrorMessage,
    };
    await createClientSideFailedPayment(errorObject, event);
  } else {
    removeFlashErrorMessage();
    enableCheckoutSubmitButton(event);
  }
};

const paymentSubmitBtnHandler = async (dropin, event) => {
  event.preventDefault();
  if (creditCardSubmitDisabled(event.target)) {
    return;
  }

  try {
    let stringifiedPaymentData = '';
    if (nonProductionEnvironment() && mockedResponseBody()) {
      stringifiedPaymentData = mockedResponseBody();
    } else {
      const paymentData = await dropin.handleSubmit(event);
      stringifiedPaymentData = JSON.stringify(paymentData);
    }

    if (expectedFormatForDropinResponse(stringifiedPaymentData)) {
      ebanxPaymentDataHandler(stringifiedPaymentData);
    } else {
      // CAREFUL with this alert! We don't want to send any sensitive data!
      const customError = new Error('Unexpected response from Ebanx JS dropin');
      Sentry.captureException(customError, { extra: errorLogData(stringifiedPaymentData) });

      throw new Error(stringifiedPaymentData); // so flow continues in the catch block
    }
  } catch (error) {
    await paymentSubmitErrorHandler(error, event);
  }
};

const mountElements = (paymentForm, dropin) => {
  paymentForm.addEventListener('submit', paymentFormPreventSubmitOnEnterKey);

  const fullPaymentAmount = paymentForm.dataset.amount;

  dropin.mount('.payment__payment-method', {
    amount: fullPaymentAmount,
    values: bootstrapper.ebanxUserDataToPrefill || {},
    lookAndFeel: {
      name: 'vanilla',
    },
    instalments: bootstrapper.ebanxInstallmentsEnabled ? 12 : 1,
    paymentMethods: ['creditcard', 'debitcard'],
  });

  const paymentSubmitBtn = paymentForm.querySelector('.js-credit-card-submit-btn');
  paymentSubmitBtn.addEventListener('click', paymentSubmitBtnHandler.bind(null, dropin));
};

const disablePrefilledInputs = () => {
  const { customerName, customerEmail } = bootstrapper.ebanxUserDataToPrefill || {};

  ['customer-name', 'customer-email'].forEach((name) => {
    if (customerName && customerEmail) {
      const element = document.querySelector(`input[name='${name}']`);
      element.disabled = true;
      element.style.opacity = '50%';
    }
  });
};

export const ebanxGateway = () => {
  if (ebanxCheckoutEnabled()) {
    const paymentForm = document.getElementById('payment-form');
    const downCaseCountryOfResidenceCode = countryOfResidenceCodeLowerCase();

    if (downCaseCountryOfResidenceCode) {
      window.EBANX.init({
        publicIntegrationKey: bootstrapper.ebanxPublicIntegrationKey,
        country: downCaseCountryOfResidenceCode,
        mode: bootstrapper.ebanxEnvironment,
      });

      const dropin = window.EBANX.dropin.create();

      dropin.onComplete(() => {
        const paymentSubmitBtn = paymentForm.querySelector('.js-credit-card-submit-btn');
        paymentSubmitBtn.removeAttribute('disabled');
      });

      mountElements(paymentForm, dropin);
      dropin.onReady(disablePrefilledInputs);
    }
  }
};

export default ebanxGateway;
