import getPageChunk from './lazyChunks';

const openHandler = (e) => {
  if (e.currentTarget.hasAttribute('data-target')) {
    const id = e.currentTarget.getAttribute('data-target');
    const targetElement = document.getElementById(id);
    const heroVideoModal = document.getElementById('hero-video-modal');

    if (targetElement) {
      targetElement.classList.add('modal--open');
      targetElement.classList.remove('modal--close');
    }

    if (heroVideoModal) {
      getPageChunk('marketing/video_on_hero/video_on_hero', 'loadHeroVideoClick');
    }

    e.preventDefault();
  }
};

const close = (modal) => {
  modal.classList.remove('modal--open');
  modal.classList.add('modal--close');
};

const closeAll = () => {
  const modals = document.getElementsByClassName('modal');
  const heroVideoModal = document.getElementById('hero-video-modal');

  if (heroVideoModal) {
    getPageChunk('marketing/video_on_hero/video_on_hero', 'pauseHeroVideo');
  }

  for (let i = 0; i < modals.length; i += 1) {
    close(modals[i]);
  }
};

const dismissHandler = (e) => {
  if (e.currentTarget.getAttribute('data-dismiss') === 'modal' || e.currentTarget.classList.contains('modal')) {
    const id = e.currentTarget.getAttribute('data-target');
    const modal = document.getElementById(id);

    if (modal) {
      close(modal);
    } else {
      closeAll();
    }

    e.preventDefault();
  }
};

const dismissHandlerWithEsc = (e) => {
  const keyDown = e.keyCode;

  if (keyDown === 27) closeAll();
};

const disconnectModals = (elements, dismissers) => {
  for (let i = 0; i < elements.length; i += 1) {
    elements[i].removeEventListener('click', openHandler);
  }

  for (let j = 0; j < dismissers.length; j += 1) {
    dismissers[j].removeEventListener('click', dismissHandler);
  }

  document.removeEventListener('keydown', dismissHandlerWithEsc);
};

export default () => {
  const elements = document.querySelectorAll('[data-toggle]');

  for (let i = 0; i < elements.length; i += 1) {
    elements[i].addEventListener('click', openHandler);
  }

  const dismissers = document.querySelectorAll('[data-dismiss]');

  for (let j = 0; j < dismissers.length; j += 1) {
    dismissers[j].addEventListener('click', dismissHandler);
  }

  document.addEventListener('keydown', dismissHandlerWithEsc);

  return () => { disconnectModals(elements, dismissers); };
};
