import strftime from 'strftime';
import bootstrapper from '../bootstrapper';

const stringToLang = (lang) => {
  switch (lang) {
    case 'es':
      return 'es_MX';
    case 'pt':
      return 'pt_BR';
    case 'fr':
      return 'fr_FR';
    case 'it':
      return 'it_IT';
    default:
      return 'en_US';
  }
};

const getHtmlForNoLineBreak = (node) => {
  if (node.hasAttribute('data-line-break') || node.hasAttribute('student-program-card')) {
    return ', ';
  }
  return ",<br class='d-lg-none'>";
};

const transformDateToUserTimezone = (lineBreak = true) => {
  const { lang } = document.documentElement;
  const strftimeIT = strftime.localizeByIdentifier(stringToLang(lang));
  const nodes = document.querySelectorAll('[data-utc-date]');
  const schoolDateFormat = bootstrapper.dateFormat;

  nodes.forEach((node) => {
    const value = node.getAttribute('data-utc-date');
    const nodeDayFormat = node.getAttribute('data-day-format');
    const nodeTimeFormat = node.getAttribute('data-time-format');
    const nodeTimeFirst = node.getAttribute('data-time-time-first') === 'true';

    const dayFormat = nodeDayFormat || schoolDateFormat;
    const timeFormat = nodeTimeFormat || '%l:%M %p';

    let nodeLineBreak = lineBreak;
    if (node.hasAttribute('data-line-break')) {
      nodeLineBreak = node.getAttribute('data-line-break') === 'true';
    }

    if (value === 'TBD') {
      node.innerHTML = value;
    } else {
      const dateObj = new Date(value);
      const formattedDate = strftimeIT(dayFormat, dateObj);
      const formattedTime = strftimeIT(timeFormat, dateObj);

      if (nodeTimeFirst) {
        node.innerHTML = `${formattedTime}${nodeLineBreak ? '<br>' : getHtmlForNoLineBreak(node)}${formattedDate}`;
      } else {
        node.innerHTML = `${formattedDate}${nodeLineBreak ? '<br>' : getHtmlForNoLineBreak(node)}${formattedTime}`;
      }
    }
  });
};

export default transformDateToUserTimezone;
