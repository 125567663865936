import {
  razorpayCheckoutEnabled, installmentsNumber, disableButton, enableButton,
  displayFlashErrorMessage, removeFlashErrorMessage, paymentFormPreventSubmitOnEnterKey,
  transactionUUID,
} from './checkoutHelpers';
import { showLoadingSpinner, hideLoadingSpinner } from './loadingSpinner';
import bootstrapper from '../bootstrapper';
import sendTrackpointEvent from '../trackpoint/sendEvent';
import { trackPaymentsEvent } from './trackPaymentsEvent';
import { clientSideFailedPaymentPayload, createFailedPayment } from './createFailedPayment';

let failedAttemptPaymentId;

const razorpayRequest = async (body, action, url) => {
  const CSRFToken = document.querySelector('input[name="authenticity_token"]')?.value;

  return fetch(url, {
    method: action,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json; charset=UTF-8',
      'X-CSRF-Token': CSRFToken,
    },
    body: JSON.stringify(body),
  });
};

const afterModalClosedRequest = async (razorpayInfo, data) => {
  const requestData = {
    url: `${window.location.origin}${data.success_callback_path}`,
    method: 'PATCH',
  };

  const response = await razorpayRequest(razorpayInfo, requestData.method, requestData.url);
  window.location = response.url;
};

const closedModalRedirections = (paymentInstallmentId) => {
  if (window.location.href === `${window.location.origin}/payment_installments/${paymentInstallmentId}/payments/new`) {
    window.location = `${window.location.origin}/${bootstrapper.locale}/enrollments/`;
  } else {
    window.location.reload();
  }
};

const createFailedPaymentWithError = async (error) => {
  const body = clientSideFailedPaymentPayload('razorpay', error, null, installmentsNumber());
  createFailedPayment(body);
};

const updateFailedPaymentAndRedirect = (data) => {
  const paymentId = data.payment_id;
  const paymentInstallmentId = data.payment_installment_id;
  const failedPaymentsUrl = `${window.location.origin}/${bootstrapper.locale}/failed_payments/${paymentId}`;
  const CSRFToken = document.querySelector('input[name="authenticity_token"]')?.value;
  return fetch(failedPaymentsUrl, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json; charset=UTF-8',
      'X-CSRF-Token': CSRFToken,
    },
    body: JSON.stringify(paymentId),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response);
      }
      closedModalRedirections(paymentInstallmentId);
    });
};

const requestPayload = data => ({
  key: bootstrapper.razorpayErulearningIn,
  amount: data.amount,
  currency: data.currency,
  name: data.title_message,
  order_id: data.razorpay_order_id,
  payment_id: data.payment_id,
  payment_installment_id: data.payment_installment_id,
  timeout: parseInt(bootstrapper.razorpayModalTimeout, 10),
  handler (response) {
    showLoadingSpinner();
    afterModalClosedRequest(response, data);
  },
  modal: {
    ondismiss () {
      showLoadingSpinner();
      updateFailedPaymentAndRedirect(data);
    },
  },
  prefill: {
    name: data.name,
    email: data.email,
  },
  readonly: { email: true },
  remember_customer: false,
});

const couponCode = () => {
  const couponField = document.querySelector('.js-coupon-code-field');
  const coupon = couponField?.classList.contains('filled') ? couponField?.value : '';

  return coupon;
};

const createRazorpayOrderPayload = () => {
  const payload = {
    coupon: couponCode(),
    shop_payment_installment: {
      number_of_installments: installmentsNumber(),
      transaction_uuid: transactionUUID(),
    },
  };

  return payload;
};

const createRazorpayOrderRequest = async () => {
  const paymentForm = document.getElementById('payment-form');
  const paymentInstallmentUrl = paymentForm.action;
  const body = createRazorpayOrderPayload();
  const response = await razorpayRequest(body, 'POST', paymentInstallmentUrl);
  const responseData = await response.json();

  if (response.ok) return responseData;

  throw new Error(responseData.error);
};

const openRazorpayModal = (data) => {
  const modalData = requestPayload(data);
  const razorpayModal = new window.Razorpay(modalData);
  razorpayModal.on('payment.failed', (response) => {
    const paymentId = response?.error?.metadata?.payment_id;
    if (paymentId !== failedAttemptPaymentId) {
      createFailedPaymentWithError(response.error);
      failedAttemptPaymentId = paymentId;
    }
  });

  razorpayModal.open();
};

const paymentProcess = () => {
  const paymentForm = document.getElementById('payment-form');
  const paymentButtons = Array.from(document.getElementsByClassName('js-razorpay-payment-button'));

  paymentButtons.forEach(
    (paymentButton) => {
      paymentForm.addEventListener('submit', paymentFormPreventSubmitOnEnterKey);

      paymentButton.addEventListener('click', (e) => {
        e.preventDefault();
        e.target.blur();

        showLoadingSpinner();
        disableButton(paymentButton);
        removeFlashErrorMessage();
        createRazorpayOrderRequest()
          .then((res) => {
            openRazorpayModal(res);
          }).catch((err) => {
            displayFlashErrorMessage(err.message);
            enableButton(paymentButton);
          }).finally(() => {
            hideLoadingSpinner();
          });
      });
    },
  );
};

const paymentProcessOnLoad = (razorpayLoader) => {
  const data = razorpayLoader.dataset;
  const razorpayData = JSON.parse(data.razorpay);

  sendTrackpointEvent({
    ...bootstrapper.trackpointMeta,
    event: 'ClickPaymentButton',
  });
  openRazorpayModal(razorpayData);
};

export const razorpayGateway = () => {
  const razorpayLoader = document.getElementById('js-razorpay-modal-loader');

  if (razorpayCheckoutEnabled()) {
    paymentProcess();
  } else if (razorpayLoader) {
    paymentProcessOnLoad(razorpayLoader);
    trackPaymentsEvent({ eventName: 'ClickPaymentButton' });
  }
};

export default razorpayGateway;
