import analytics from '../analytics';

export default (caller = analytics) => {
  document.querySelectorAll('[data-analytics]').forEach((element) => {
    JSON.parse(element.dataset.analytics).forEach((analyticsEvent, index) => {
      element.addEventListener(analyticsEvent.type, () => {
        const {
          event, properties, options,
        } = JSON.parse(element.dataset.analytics)[index];

        caller.track(event, properties, options);
      });
    });
  });
};
