const initFileFields = (fileInput, selectedFileContainer, selectedFilenameTextElement) => {
  if (selectedFilenameTextElement.innerHTML === '') {
    fileInput.parentNode.classList.remove('hidden');
  } else {
    selectedFileContainer.classList.remove('hidden');
    fileInput.removeAttribute('required');
  }
};

const humanReadableFileSize = (size) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const unit = ['B', 'KB', 'MB', 'GB', 'TB'][i];

  return `${(size / (1024 ** i)).toFixed(2) * 1} ${unit}`;
};

const reflectSelectedFile = (
  fileInput,
  selectedFileContainer,
  selectedFilenameTextElement,
  selectedFileSizeTextElement) => {
  fileInput.addEventListener('change', () => {
    if (fileInput.value !== '') {
      fileInput.parentNode.classList.add('hidden');
      selectedFileContainer.classList.remove('hidden');
      selectedFilenameTextElement.innerText = fileInput.files[0].name;
      selectedFileSizeTextElement.innerText = humanReadableFileSize(fileInput.files[0].size);
    }
  });
};

const removeSelectedFile = (fileInput, selectedFileContainer, removeFileButton, hiddenInput) => {
  removeFileButton.addEventListener('click', () => {
    selectedFileContainer.classList.add('hidden');
    fileInput.value = '';
    fileInput.parentNode.classList.remove('hidden');
    hiddenInput.value = '';
  });
};

export default () => {
  const fieldContainers = document.querySelectorAll('.js-file-upload');
  fieldContainers.forEach((container) => {
    const fileInput = container.querySelector('[type=file]');
    const selectedFileContainer = container.querySelector('.js-selected-file');
    const selectedFilenameTextElement = container.querySelector('.js-selected-filename');
    const selectedFileSizeTextElement = container.querySelector('.js-selected-filesize');
    const removeFileButton = container.querySelector('.file-upload__remove-file-btn');
    const hiddenInput = container.querySelector('.js-stored-filename');

    initFileFields(fileInput, selectedFileContainer, selectedFilenameTextElement);
    reflectSelectedFile(
      fileInput,
      selectedFileContainer,
      selectedFilenameTextElement,
      selectedFileSizeTextElement,
    );
    removeSelectedFile(fileInput, selectedFileContainer, removeFileButton, hiddenInput);
  });
};
