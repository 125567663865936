const ROUTES = {
  '/es/profile/edit': 'profiles',
  '/es/profile/new': 'profiles',
  '/es/profile': 'profiles',
  '/en/profile/edit': 'profiles',
  '/en/profile/new': 'profiles',
  '/en/profile': 'profiles',
  '/profile/edit': 'profiles',
  '/profile/new': 'profiles',
  '/profile': 'profiles',
};

export const controllerName = path => ROUTES[path];
export default controllerName;
