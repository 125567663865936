import { getCookie } from './cookie';

const setAttemptsCookie = () => {
  const date = new Date();
  date.setDate(date.getDate() + 1); // 1 day

  const expires = `; expires=${date.toUTCString()}`;
  document.cookie = `previous_attempt=true; expires=${expires}; path=/`;
};

const importRejectionHandler = (error) => {
  if (getCookie('previous_attempt')) {
    throw new Error(error.name);
  }

  setAttemptsCookie();
  // Hard reload will refresh user cache and try again.
  window.location.reload(true);
};

export default importRejectionHandler;
