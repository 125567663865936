import updateApplicationDeadline from './updateApplicationDeadline';
import updateStartApplicationLink from './updateStartApplicationLink';
import transformDateToUserTimezone from '../marketing/transformDateToUserTimezone';

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('select.batch').forEach((element) => {
      element.addEventListener('change', updateApplicationDeadline);
      element.addEventListener('change', updateStartApplicationLink);
    });
  });
  transformDateToUserTimezone(false);
};
