const toggleConsentText = () => {
  const changeProgramHandler = (e) => {
    const programSelect = e.target;
    const wrapper = document.querySelector('#consent-text');
    const wrapperData = wrapper.dataset;
    const legacyConsentPrograms = wrapperData.legacyConsentPrograms.split(' ');
    const consentTextLabel = wrapperData.consentText;
    const legacyConsentTextLabel = wrapperData.legacyConsentText;
    const programSfid = programSelect.value;

    if (!!wrapperData.legacyConsentPrograms.trim() && legacyConsentPrograms.includes(programSfid)) {
      wrapper.innerHTML = legacyConsentTextLabel;
    } else {
      wrapper.innerHTML = consentTextLabel;
    }
  };

  const loadHandler = () => {
    const programSelect = document.querySelector('#user_program_select');
    if (programSelect) {
      programSelect.addEventListener('change', changeProgramHandler);
      programSelect.dispatchEvent(new CustomEvent('change'));
    }
  };

  if (document.readyState === 'loading') {
    // loading hasn't finished yet so it is ok to listen for domcontentloaded
    document.addEventListener('DOMContentLoaded', loadHandler);
  } else {
    loadHandler();
  }
};

export default toggleConsentText;
