const trackPaymentsEvent = async (args) => {
  const trackEventsUrl = `${window.location.origin}/payment_events`;
  const CSRFToken = document.querySelector('meta[name="csrf-token"')?.content;
  const uriParts = window.location.href.split('/');
  const paymentInstallmentId = uriParts[uriParts.indexOf('payment_installments') + 1];
  const seshatData = JSON.parse(document.querySelector('head').getAttribute('data-trackpoint-meta'));
  const body = {
    event_name: args.eventName,
    payment_installment_id: paymentInstallmentId,
    ...seshatData,
  };

  return fetch(trackEventsUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json; charset=UTF-8',
      'X-CSRF-Token': CSRFToken,
    },
    body: JSON.stringify(body),
  });
};

const trackClickPaymentButton = (btnClassName) => {
  const paymentButtons = document.getElementsByClassName(btnClassName);
  const args = { eventName: 'ClickPaymentButton' };

  Array.from(paymentButtons).forEach(
    (paymentButton) => {
      paymentButton.addEventListener('click', (e) => {
        e.preventDefault();

        trackPaymentsEvent(args);
      });
    },
  );
};

const trackClickPaymentButtonEvents = () => {
  ['js-credit-card-submit-btn', 'js-razorpay-payment-button'].forEach(
    (btnClassName) => {
      trackClickPaymentButton(btnClassName);
    },
  );
};

export {
  trackClickPaymentButtonEvents,
  trackClickPaymentButton,
  trackPaymentsEvent,
};
