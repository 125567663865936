export default (event) => {
  const { target } = event;
  const startApplicationLinkElement = target.parentElement.getElementsByClassName('start-application-link')[0];
  const selectedOption = target.options[target.selectedIndex];
  const batchSfid = selectedOption.value;

  if (batchSfid) {
    const currentLink = startApplicationLinkElement.href;
    const newLink = currentLink.replace(/batches\/\w*\/enrollments/i, `batches/${batchSfid}/enrollments`);
    startApplicationLinkElement.href = newLink;
  }
};
